


























































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { View, ComponentType, ViewItem, ViewTypes } from '@/models'
import { ViewFragment, ViewUpdateFragment } from './fragments'
import gql from 'graphql-tag'
import cleanData from '@/utils/gql/cleanData'
import _isEqual from 'lodash/isEqual'
import _filter from 'lodash/filter'
import _cloneDeep from 'lodash/cloneDeep'
import * as ComponentTypes from '@/components/componentTypes'
import Field from '@/components/fields/Field.vue'
import Preview from '@/views/Environment/View/ViewComponents.vue'
import Navbar from '@/views/Environment/View/Navbar.vue'
import Loading from '@/components/Loading.vue'
import CodelessLoading from '@/components/CodelessLoading.vue'
import draggable from 'vuedraggable'
import ComponentSelect from './componentSelect.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import KeyValueList from '@/components/tools/KeyValueList.vue'
import { Field as ComponentSelectField } from '@/components/fields/componentSelect'
import { confirm, confirmDelete } from '@/components/dialogs'
import resolveLink from '@/utils/route/resolveLink'

@Component({
  components: {
    Preview,
    Navbar,
    draggable,
    ComponentSelect,
    ComponentEditorDialog,
    ComponentSelectField,
    KeyValueList,
    Field,
    Loading,
    CodelessLoading,
  },
  apollo: {
    savedView: {
      query: gql`
        query getView($viewId: ID, $environmentId: ID, $previewRoles: [ID]) {
          savedView: view(
            viewId: $viewId
            environmentId: $environmentId
            previewRoles: $previewRoles
          ) {
            ...View
          }
        }
        ${ViewFragment}
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          environmentId: this.environmentId,
          viewId: this.viewId,
          previewRoles: this.previewRoles,
        }
      },
    },
  },
})
export default class AdminDesignEdit extends Vue {
  @Prop() environmentId!: string
  @Prop() viewId!: string

  savedView: View | null = null
  saving = false
  dragging = false
  history: Partial<View>[] = []
  undoHistory: Partial<View>[] = []
  reactiveView: Partial<View> = {}

  previewRoles: string[] = []

  componentEditorOpen = false
  componentEditorType = ''
  componentEditorId = ''
  componentEditorItemPath: number[] = []
  previewSize = 'desktop'
  previewTheme = 'light'
  openPanels = [2, 3]

  snackbar = false
  snackbarText = ''
  snackbarColor = ''

  componentToAdd: ComponentType<any> | null = null

  containerComponents = [
    {
      name: 'layout',
      title: this.$t('admin_edit.script.containerComp.layout.title'),
      titleSingle: this.$t('admin_edit.script.containerComp.layout.single'),
      description: this.$t(
        'admin_edit.script.containerComp.layout.description'
      ),
      color: 'blue-grey',
      icon: 'border_clear',
      data: {
        type: 'layout',
        sizeLarge: '12',
        sizeMedium: '12',
        sizeSmall: '12',
        subItems: [],
      },
    },
    {
      name: 'tabs',
      title: this.$t('admin_edit.script.containerComp.tabs.title'),
      titleSingle: this.$t('admin_edit.script.containerComp.tabs.single'),
      description: this.$t('admin_edit.script.containerComp.tabs.description'),
      color: 'blue-grey',
      icon: 'tab',
      data: {
        type: 'layout',
        sizeLarge: '12',
        sizeMedium: '12',
        sizeSmall: '12',
        viewType: ViewTypes.Tabs,
        subItems: [],
        forceBorders: true,
      },
    },
  ]

  @Watch('savedView')
  updateView(newView = this.savedView) {
    this.undoHistory = []
    this.history = []
    this.view = _cloneDeep(this.savedView || {})
    this.$store.commit(
      'dashboard/setEnvironmentLinkPath',
      resolveLink(newView?.path || '/', newView?.previewParameters || {}).href
    )
  }

  mounted() {
    this.$store.commit('dashboard/setMiniDrawer', true)
  }

  beforeDestroy() {
    this.$store.commit('dashboard/setEnvironmentLinkPath', null)
  }

  get overlay(): boolean {
    return this.$store.state.dashboard.designOverlay
  }

  get view() {
    if (this.previewRoles.length > 0) {
    }
    return this.history[this.history.length - 1] || {}
  }

  set view(newView) {
    while (this.history.length > 100) this.history.pop()
    this.undoHistory = []
    this.history.push(newView)
    this.reactiveView = _cloneDeep(newView)
  }

  get componentEditorItem() {
    try {
      return this.componentEditorItemPath.reduce(
        (a: any, b: number) => {
          return a.subItems[b]
        },
        { subItems: this.reactiveView.items }
      ) as ViewItem
    } catch (e) {
      return undefined
    }
  }

  set componentEditorItem(v) {
    const clonedView = _cloneDeep(this.view)
    const target = this.componentEditorItemPath.reduce(
      (a: any, b: number) => {
        return a.subItems[b]
      },
      { subItems: clonedView.items }
    ) as ViewItem
    Object.assign(target, v)
    this.view = clonedView
  }

  @Watch('reactiveView', { deep: true })
  updateHistory(newView: View, oldView: View) {
    if (newView === oldView) {
      this.view = newView
    }
  }

  async revert() {
    if (
      await confirm(this.$t('admin_edit.script.revert.confirm').toString(), {
        okButtonText: this.$t('admin_edit.script.revert.yes').toString(),
        cancelButtonText: this.$t('admin_edit.script.revert.no').toString(),
      })
    ) {
      this.updateView()
    }
  }

  get dirty() {
    return !_isEqual(this.view, this.savedView)
  }

  openComponentEditor(type: string, componentId: string, path: number[]) {
    this.componentEditorOpen = true
    this.componentEditorType = type
    this.componentEditorId = componentId
    this.componentEditorItemPath = path
  }

  addComponent(componentType: number) {
    this.componentToAdd = this.componentTypes[componentType]
  }

  async save() {
    if (!this.view || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($viewId: ID, $view: UpdateViewInput) {
            updateView(viewId: $viewId, view: $view) {
              ...View
            }
          }
          ${ViewFragment}
        `,
        // Parameters
        variables: {
          viewId: this.view._id,
          view: cleanData(this.view, ViewUpdateFragment),
        },
      })

      this.savedView = result.data.updateView

      this.snackbar = true
      this.snackbarText = this.$t('admin_edit.script.save.text').toString()
    } catch (e) {
      this.$emit('error', e)
      this.snackbar = true
      this.snackbarText = 'Error: ' + e.message
      this.snackbarColor = 'error'
    } finally {
      this.saving = false
    }
  }

  undo() {
    if (this.history.length > 1) {
      this.undoHistory.push(this.history.pop()!)
      this.reactiveView = _cloneDeep(this.view)
    }
  }

  redo() {
    if (this.undoHistory.length > 0) {
      this.history.push(this.undoHistory.pop()!)
      this.reactiveView = _cloneDeep(this.view)
    }
  }

  async cleanupViewItems() {
    if (
      !(await confirm(this.$t('admin_edit.script.cleanupVi.text').toString(), {
        okButtonText: this.$t('admin_edit.script.cleanupVi.okBtn').toString(),
        okButtonColor: 'warning',
        okButtonIcon: 'stream',
      }))
    ) {
      return
    }
    this.reactiveView.items?.splice(0, this.reactiveView.items.length)
  }

  async deleteItem() {
    if (
      !(await confirmDelete(
        this.$t('admin_edit.script.deleteItem.confirm').toString()
      ))
    ) {
      return
    }
    if (!this.view || this.saving) {
      return
    }
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($viewId: ID) {
            removeView(viewId: $viewId)
          }
        `,
        // Parameters
        variables: {
          viewId: this.view._id,
        },
      })

      await this.$router.replace({ name: 'adminDesign' })
    } catch (e) {
      this.$emit('error', e)
      this.snackbar = true
      this.snackbarText = 'Error: ' + e.message
      this.snackbarColor = 'error'
    } finally {
      this.saving = false
    }
  }

  addContainerComponent(index: number) {
    const clonedView = _cloneDeep(this.view)
    if (!clonedView) return
    if (!clonedView.items) clonedView.items = []
    clonedView.items.push(_cloneDeep(this.containerComponents[index]?.data))
    this.view = clonedView
    setTimeout(() => {
      window.scrollTo(window.scrollX, document.body.scrollHeight)
    }, 16)
  }

  handleAddComponent(component: ViewItem, openEditorId = '') {
    const clonedView = _cloneDeep(this.view)
    if (!clonedView) return
    if (!clonedView.items) clonedView.items = []
    const newIndex = clonedView.items.push(_cloneDeep(component)) - 1
    this.view = clonedView
    const componentType = this.componentToAdd?.namespace || component.type + 's'
    this.componentToAdd = null
    setTimeout(() => {
      if (openEditorId) {
        this.openComponentEditor(componentType, openEditorId, [newIndex])
      }
      window.scrollTo(window.scrollX, document.body.scrollHeight)
    }, 16)
  }

  get componentTypes() {
    return _filter(
      ComponentTypes as unknown as Record<string, ComponentType<any>>,
      (ct) =>
        !!ct.view &&
        (!ct.allowedEnvironments ||
          ct.allowedEnvironments.includes(this.environmentId))
    )
  }

  get containerComponentData() {
    return this.containerComponents.map((c) => c.data)
  }
}
